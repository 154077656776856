@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/fonts';

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.root {
    --opacityEasing: cubic-bezier(0.46, 0, 0.42, 1.01);
    --scaleEasing: cubic-bezier(0.5, 0, 0.12, 1);
    --heightEasing: cubic-bezier(0.47, 0, 0.17, 1);

    max-width: var(--block-max-width-plus-padding);
    padding: 0 var(--block-padding-side) 2.4rem;

    &.lastBlock {
        padding: 0 var(--block-padding-side) var(--block-padding-end);
    }
}

.chatbot {
    @include rounded-corners($overflow: hidden);

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--block-padding-end) var(--block-padding-side);
    margin-bottom: 1rem;
    background-color: #ffe4af;
    color: var(--colors-blue);
}

.newLabel {
    display: inline-block;
    padding: 0.6rem 0.7rem 0.5rem;
    border-radius: 0.6rem;
    background: var(--colors-white);
    color: var(--colors-blue);
    font-family: $GTWalsheimCond;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    line-height: 1;
    text-transform: uppercase;
}

.chatbotHeading {
    max-width: 12em;
    margin-top: 1.4rem;
    margin-bottom: 3.4rem;
    text-align: center;
}

.grid {
    --gridColumnAreas: 'a a';

    @include rounded-corners($overflow: hidden);

    display: grid;
    background-color: rgba(color('blue'), 0.2);
    clip-path: inset(0% 0% 0% 0%);
    grid-auto-columns: 1fr;
    grid-gap: 0.1rem;
    grid-template-areas: var(--gridColumnAreas);
    transition: clip-path calc(var(--timing) * 1.25) var(--heightEasing);

    &.exit {
        clip-path: inset(0% 0% 100% 0%);
    }

    &.hidden {
        height: 0;
        clip-path: inset(0% 0% 100% 0%);
        opacity: 0;
        pointer-events: none;
        transition: none;
        visibility: hidden;
    }
}

.icon {
    svg {
        width: 5rem;
    }
}

.card {
    position: relative;
    display: flex;
    padding: 0;
    border: 0;
    margin: 0;
    appearance: none;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    text-transform: none;

    &:focus-visible {
        border: 0.2rem solid blue !important;
    }

    &::before {
        display: block;
        padding-top: 100%;
        content: '';
    }

    &.disabled {
        pointer-events: none;
    }
}

.resetButton {
    background-color: transparent;
    opacity: 0;
    transition: opacity $default $linear 0s;
    visibility: hidden;

    button {
        width: 100%;
    }

    &:focus-visible {
        border: 0.2rem solid blue !important;
    }

    &.isVisible {
        opacity: 1;
        transition: opacity $default $linear;
        visibility: visible;
    }
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    text-align: center;
    transform: scale(1);

    @media (hover: hover) {
        &:hover {
            background-color: rgba(250, 251, 252, 100%);
            cursor: pointer;
        }
    }

    transition: transform var(--timing) var(--scaleEasing),
        opacity var(--timing) var(--opacityEasing);

    &.exit {
        opacity: 0;
        transform: scale(0.75);
    }
}

.label {
    max-width: 36rem;
    padding: 1rem 2rem 0;
    font-size: clamp(1.6rem, 0.2641rem + 3.5623vw, 3rem);

    // between viewport width 375px to 768px, scale from size 1.6rem to 3rem
    // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
}

.detail {
    &.hidden {
        height: 0;
        opacity: 0;
        pointer-events: none;
        transition: none;
        visibility: hidden;
    }

    &.fade {
        animation: calc(var(--timing) / 2) var(--opacityEasing) 0s fade-out
            forwards;
    }
}

@include medium {
    .root {
        padding-bottom: 3.6rem;
    }

    .icon {
        svg {
            width: 8rem;
        }
    }

    .label {
        padding: 2.4rem 2.4rem 0;
    }

    .chatbotHeading {
        max-width: 19em;
    }
}

@include large {
    .grid {
        --gridColumnAreas: 'a a a';
    }

    .label {
        max-width: 38rem;
    }
}

@include xxLarge {
    .root {
        margin: 0 auto;
    }
}
