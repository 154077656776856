@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/fonts';

.root {
    display: flex;
    flex-direction: column;

    align-items: center;

    padding: var(--block-padding-end) var(--block-padding-side);

    background-color: var(--colors-white);

    border-radius: 1rem;
    color: var(--colors-blue);

    text-align: center;
}

.headline {
    margin: 1.2rem 3.2rem;
    text-transform: uppercase;
}

.body {
    max-width: 50rem;
    padding: 0 3.2rem;
}

.icon {
    width: 6rem;
}

.resetButton {
    width: fit-content;
    margin: 3rem 0;
}

.social {
    display: flex;
    width: 100%;
    max-width: 35rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    a {
        padding-right: 1rem;

        &:last-child {
            padding-right: 0;
        }
    }

    svg {
        height: 2.25rem;
    }
}

@include medium {
    .headline {
        margin: 2.4rem 0;
    }

    .resetButton {
        margin: 4.8rem 0;
    }

    .icon {
        width: 8rem;
    }

    .social {
        a {
            padding-right: 2.4rem;

            &:last-child {
                padding-right: 0;
            }
        }

        svg {
            height: 3rem;
        }
    }
}
