@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    display: flex;

    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1.4rem;
}

.innerWrapper {
    position: relative;

    width: 100%;
    min-height: 3.74rem;

    &.collapsed {
        min-height: 0;
    }

    transition: min-height $fast $easeIn;
}

.inputWrapper {
    position: relative;

    display: flex;

    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    border-bottom: 0.1rem solid rgba(color('blue'), 0.25);
}

.topSuggestion {
    position: absolute;
    top: 0;
    left: 0;

    color: rgba(color('blue'), 0.25);
    line-height: 130%;

    transition: all $default cubic-bezier(0.215, 0.61, 0.355, 1);

    user-select: none;

    em {
        font-style: normal;
    }

    em:first-of-type {
        color: transparent;
    }
    // ^ We em-wrap the part of the suggestion that matches the user input and
    // hide it so that we don't have to worry about upper or lower cases

    @media (hover: hover) {
        &:hover {
            color: var(--colors-blue);
        }
    }
}

.input {
    width: 90%;
    padding: 0 0 1.4rem 0;

    border: 0;

    background-color: transparent;
    color: var(--colors-blue);

    line-height: 130%;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: rgba(color('blue'), 0.4);
        font-weight: 500;
    }
}

.closeButton {
    svg {
        width: 1rem;
        height: 1rem;
    }
}

.suggestedStoresContainer {
    position: relative;
    height: 12rem;

    &.collapsed {
        display: none;
    }
}

.suggestions {
    position: absolute;
    z-index: var(--z-storeSuggestions);
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    padding: 0;

    margin: 0;

    animation: staggerIn $slow;

    list-style-type: none;
}

.store {
    padding: 0;
    margin: 1.2rem 0 0 0;

    color: rgba(color('blue'), 0.25);

    cursor: pointer;

    transition: all $default cubic-bezier(0.215, 0.61, 0.355, 1);

    &:first-child {
        margin-top: 2.4rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    em {
        font-style: normal;
    }

    em:first-of-type {
        color: var(--colors-blue);
    }

    @media (hover: hover) {
        &:hover {
            color: var(--colors-blue);

            em:first-of-type {
                color: var(--colors-blue);
            }
        }
    }
}

@include large {
    .innerWrapper {
        min-height: 3.8rem;

        &.collapsed {
            min-height: 0;
        }
    }
}
