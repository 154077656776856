@import 'styles/animations';
@import 'styles/colors';

.accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    background-color: var(--color-white);
    opacity: 0;
    pointer-events: none;
    transform: translateY(100px);
    transition-duration: var(--timing);
    transition-property: opacity, transform;
    visibility: hidden;

    &.enter {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }

    &.exit {
        opacity: 0;
        transform: translateY(0);
        transition-timing-function: var(--scaleEasing);
        visibility: visible;

        &.upcoming {
            transform: translateY(100px);
        }
    }

    &.idle {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
        transition-delay: calc(var(--timing) * 0.8);
        visibility: visible;

        &.previous {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.accordionInner {
    width: 100%;
    max-width: var(--block-max-width);
    background: var(--colors-white);
    color: var(--colors-blue);
}

.accordionToggle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: left;
    padding: 2rem;
    border: none;
    background: transparent;
    box-shadow: none;
    color: var(--colors-blue);
    outline: none;
    text-transform: uppercase;

    &:focus-visible {
        border: 0.2rem solid blue !important;
    }

    &.isClickable {
        @media (hover: hover) {
            &:hover {
                background-color: rgba(250, 251, 252, 100%);
            }
        }
    }
}

.pagination {
    display: flex;
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
    margin-right: 2rem;
    background: rgba(0, 30, 96, 5%);
}

.label {
    text-align: left;
}

.panel {
    display: flex;
    overflow: hidden;
    justify-content: center;
    opacity: 0;
    transition: height var(--timing) var(--heightEasing);

    &.open {
        opacity: 1;
        transition: opacity var(--timing) linear var(--timing),
            height var(--timing) var(--heightEasing);
    }

    &.close {
        opacity: 0;
        transition: height var(--timing) var(--heightEasing),
            opacity var(--timing) linear;
    }
}

.panelInner {
    width: 100%;
}

.panelContent {
    width: 100%;
}
